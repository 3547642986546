import React from "react"
import GiftCardsMobile from "../../../../../components/body/pages/mobile/ng/legal/disclaimer/giftCards"
import NoheaderFooter from "../../../../../components/Noheaderfooter"
import SEO from "../../../../../components/seo"

// src/components/body/pages/en-ng/legal/disclaimer/giftCards.js
const DisclaimerGiftCards = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/disclaimer/giftCards"}
      title="Disclaimer GiftCards | Kuda | The Money App for Africans"
    />
    <GiftCardsMobile />
  </NoheaderFooter>
)

export default DisclaimerGiftCards
